@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

:root {
  --bg-main: hsl(217, 54%, 11%);
  --bg-card: hsl(216, 50%, 16%);
  --colour-blue: hsl(215, 51%, 70%);
  --colour-cyan: hsl(178, 100%, 50%);
  --colour-white: hsl(0, 0%, 100%);
  --line-colour: hsl(215, 32%, 27%);
}

/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Project Styles */
body {
  background-color: var(--bg-main);
  font-family: 'Outfit', sans-serif;
  color: var(--colour-blue);
  font-weight: 300;
  line-height: 1.6;
}

.container {
  /* height: 100vh; */
  display: block;
  margin-top: 1.5rem;
}

/* Card */
.input-search {
  background-color: var(--bg-card);
  width: min(87.5%, 22rem);
  color: white;

  border-radius: 15px;
  padding: 1.5rem 1.6rem 2rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
  font-size: 1.4rem;
  font-weight: 400;

  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5);
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* Card */
.main-card {
  background-color: var(--bg-card);
  width: min(87.5%, 22rem);
  margin: 9.5rem auto 0.3rem;
  border-radius: 15px;
  padding: 1.5rem 1.6rem 2rem;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5);
}

/* Main Image */
.image-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
}

.main-image {
  display: block;
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: var(--colour-cyan);
  border-radius: 10px;
}

.view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: none;
}

.image-container:hover .overlay {
  opacity: 0.5;
  cursor: pointer;
}

.image-container:hover .overlay + .view {
  display: block;
}

/* Main Content */
.title {
  color: var(--colour-white);
  margin: 1.4rem 0 0.75rem;
  font-size: 1.4rem;
  font-weight: 400;
}

.description {
  font-size: 1.1rem;
  letter-spacing: 0.015em;
  margin-bottom: 1rem;
  font-weight: 300;
  min-height: 7rem;
}

.eth-info {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1.4rem;
}

.eth {
  color: var(--colour-cyan);
  font-weight: 600;
}

.info {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
}

/* Creator Info */
.creator-info {
  display: flex;
  border-top: 1px solid var(--line-colour);
  padding-top: 1rem;
  align-items: center;
}

.avatar {
  border: 1px solid var(--colour-white);
  border-radius: 50%;
  width: 2rem;
  height: auto;
  margin-right: 1rem;
}

.creator-text {
  font-weight: 300;
}

.creator-name {
  color: var(--colour-white);
}

.title:hover,
.creator-name:hover {
  color: var(--colour-cyan);
  cursor: pointer;
}

/* Footer Section */
.attribution {
  font-size: 11px;
  text-align: center;
}

.attribution a {
  color: var(--colour-white);
  text-decoration: none;
}

.attribution a:hover {
  color: var(--colour-cyan);
}
.alert {
  width: min(87.5%, 22rem);
  margin-right: auto;
  margin-left: auto;
  color: red;
}
.elements {
  width: min(87.5%, 22rem);
  margin-right: auto;
  margin-left: auto;
}

/* Media Queries */
@media screen and (max-width: 300px) {
  .eth-info {
    flex-direction: column;
  }
}
